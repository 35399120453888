import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface CartItem {
  productId: string;
  name: string;
  price: number;
  quantity: number;
  coverPicture: string;
  stock: number;
}

export interface CartState {
  items: CartItem[];
}

@Module({ namespaced: true }) // Ensure the module is namespaced
export default class CartModule extends VuexModule implements CartState {
  items: CartItem[] = [];

  // Getter for cart items
  get cartItems(): CartItem[] {
    return this.items;
  }

  // Getter for cart total price
  get cartTotal(): number {
    return this.items.reduce((total, item) => total + item.price * item.quantity, 0);
  }

  // Mutation to add an item to the cart or increase quantity if it already exists
  @Mutation
  ADD_TO_CART(item: CartItem) {
    const existingItem = this.items.find(i => i.productId === item.productId);
    if (existingItem) {
      const totalQuantity = existingItem.quantity + item.quantity;
      if (totalQuantity <= existingItem.stock) {
        existingItem.quantity = totalQuantity;
      } else {
        existingItem.quantity = existingItem.stock; 
      }
    } else {
      if (item.quantity <= item.stock) {
        this.items.push(item);
      } else {
        item.quantity = item.stock; 
        this.items.push(item);
      }
    }
  }
  

  // Mutation to remove an item from the cart
  @Mutation
  REMOVE_FROM_CART(productId: string) {
    this.items = this.items.filter(item => item.productId !== productId);
  }

  // Mutation to update the quantity of a cart item
  @Mutation
  UPDATE_ITEM_QUANTITY({ productId, quantity }: { productId: string, quantity: number }) {
    const item = this.items.find(i => i.productId === productId);
    if (item && quantity > 0) {
      if (quantity <= item.stock) {
        item.quantity = quantity;
      } else {
        item.quantity = item.stock; 
      }
    }
  }
  

  // **New Mutation to clear the cart**
  @Mutation
  CLEAR_CART() {
    this.items = [];
  }

  // Action to add an item to the cart
  @Action
  addToCart(item: CartItem) {
    this.context.commit('ADD_TO_CART', item);
  }

  // Action to remove an item from the cart
  @Action
  removeFromCart(productId: string) {
    this.context.commit('REMOVE_FROM_CART', productId);
  }

  // Action to update the quantity of a cart item
  @Action
  updateItemQuantity({ productId, quantity }: { productId: string, quantity: number }) {
    this.context.commit('UPDATE_ITEM_QUANTITY', { productId, quantity });
  }

  @Action
  clearCart() {
    this.context.commit('CLEAR_CART');
  }
}
